import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/Layout/Layout'

import gridStyles from 'styles/grid.module.scss'

const FourOFour = ({
  data: {
    wordpressAcfOptions: { options },
  },
  location,
}) => (
  <Layout location={location}>
    <div className={gridStyles.lbhContainer}>
      <Helmet title={'Hackney Council | 404 Page not found'} />
      <div
        style={{ marginBottom: '3rem' }}
        dangerouslySetInnerHTML={{
          __html: options.four_o_four_message,
        }}
      />
    </div>
  </Layout>
)

FourOFour.propTypes = {
  location: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    wordpressAcfOptions: PropTypes.shape({
      options: PropTypes.shape({
        four_o_four_message: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export default FourOFour

export const pageQuery = graphql`
  query FourOFourIndexQuery {
    wordpressAcfOptions {
      options {
        four_o_four_message
      }
    }
  }
`
